import React, { FC, forwardRef, useRef, useState } from 'react';

import styles from './index.module.scss';

type CompRef = React.ForwardedRef<HTMLDivElement>;

type Props = {
  children: React.ReactNode;
  titleTag?: keyof JSX.IntrinsicElements;
  titleJustify?: 'space-between' | 'start';
  titleAlign?: 'center' | 'start' | 'end';
  className?: string;
  toggleClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  togglePosition?: 'right' | 'left';
  [`data-{string}`]?: string;
  onCollapse?: (collapsed: boolean) => void;
} & (
  | {
      title: string;
      renderTitle?: never;
    }
  | {
      title?: never;
      renderTitle: React.ReactNode;
    }
) &
  (
    | {
        defaultCollapsed?: boolean;
        collapsed?: never;
      }
    | {
        defaultCollapsed?: never;
        collapsed: boolean;
      }
  );

const ToggleElement: FC<{
  className: string;
  onClick: () => void;
}> = ({ className, onClick }) => (
  <button
    className={className + ' ' + styles['toggle-button']}
    data-accordion-toggle
    onClick={onClick}
  >
    <i className="fa-solid fa-chevron-down" data-type="chevron-ico" />
  </button>
);

const AccordionComp: FC<Props> = (
  {
    className = '',
    titleTag = 'h3',
    titleJustify,
    titleAlign,
    renderTitle,
    toggleClassName = '',
    titleClassName = '',
    bodyClassName = '',
    title,
    togglePosition = 'right',
    defaultCollapsed = false,
    collapsed = undefined,
    onCollapse,
    children,
    ...attributes
  },
  ref,
) => {
  const isControlled = collapsed !== undefined && onCollapse;
  const [localCollapsed, setCollapsed] = useState(defaultCollapsed);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const Header = titleTag;

  const onToggleClick = () => {
    if (isControlled) {
      onCollapse(!collapsed);
      return;
    }
    onCollapse?.(!localCollapsed);
    setCollapsed(!localCollapsed);
  };

  const isCollapsed = isControlled ? collapsed : localCollapsed;
  const Toggle = (
    <ToggleElement className={toggleClassName} onClick={onToggleClick} />
  );
  const Title = renderTitle ? (
    renderTitle
  ) : (
    <Header className={styles['heading']} onClick={onToggleClick} tabIndex={0}>
      {title}
    </Header>
  );

  return (
    <div
      ref={(node) => {
        containerRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref && Object.isExtensible(ref)) {
          ref.current = node;
        }
      }}
      className={`${className}`}
      data-collapsed={isCollapsed}
      data-accordion
      {...attributes}
    >
      <div
        className={`${styles.title} ${titleClassName}`}
        data-collapsed={isCollapsed}
        style={{
          ['--title-justify' as string]: titleJustify,
          ['--title-align' as string]: titleAlign,
        }}
      >
        {togglePosition === 'left' ? (
          <>
            {Toggle}
            {Title}
          </>
        ) : (
          <>
            {Title}
            {Toggle}
          </>
        )}
      </div>

      <div
        ref={bodyRef}
        // TODO: Implement a scrollable container which allow overflow-x.
        // TODO: The overflow would to style borders/outline
        className={`${styles['body']}
                    ${isCollapsed ? bodyClassName : ''}`}
        data-collapsed={isCollapsed}
        data-accordion-body
        onKeyDown={(e) => {
          if (!isCollapsed && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            return false;
          }
        }}
      >
        {children}
      </div>
    </div>
  );
};

const CompWithRef = forwardRef((props: Props, ref: CompRef) =>
  AccordionComp(props, ref),
);

export { CompWithRef as Accordion };
