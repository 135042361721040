import { ElementRef, FC, useEffect, useRef } from 'react';
import { MenuItem, MenuList } from 'core/components/MenuList';
import { NavMenuItem } from '..';
import { BaseButton } from '../../BaseButton';
import { useKeydownState } from '../hooks/use-keydown-state';
import { useResizeObserver } from '../hooks/use-resize-observer';
import {
  SlideEffectMenuGroup,
  useSlideEffectMenuContext,
} from './SlideEffectMenuGroup';

type Props = {
  /**
   * Use this prop to customize the component style
   */
  className?: string;
  /**
   * The navigation items to display
   * */
  items: NavMenuItem[];
  /**
   * if `true`, the nav items which space overflows the browser viewport,
   * they will be grouped into a popup menu
   * @default `false`
   * */
  groupOverflow?: boolean;
  /**
   * It set the label text used for the grouped items popup menu
   * Use this in combination with `groupOverflow` prop.
   * @default 'Show more'
   */
  groupOverflowLabel?: string;
  /**
   * It set the width size for the grouped items popup menu
   * Use this in combination with `groupOverflow` prop.
   * @default '120px'
   */
  groupOverflowWidth?: React.CSSProperties['width'];
  /**
   * Container max-width
   */
  maxWidth?: React.CSSProperties['width'];
  /**
   * Any valid `data-*` attribute to apply on the component container
   */
  [x: `data-${string}`]: string;
  /**
   * An event that is triggered when click on a menu item occurs
   */
  onItemClick?: (selectedItem: NavMenuItem) => void;
};

const SlideEffectMenubar: FC<Props> = ({
  className,
  items,
  maxWidth,
  groupOverflow = false,
  groupOverflowLabel = 'Show more',
  groupOverflowWidth = '120px',
  onItemClick = () => null,
  ...dataProps
}) => {
  const refList = useRef<ElementRef<'ul'>>(null);
  const refGroupMenu = useRef<ElementRef<'button'>>(null);

  const { isParent, onItemBlur, onItemFocus, setActiveNode } =
    useSlideEffectMenuContext();

  if (!isParent) {
    throw new Error(
      `"${SlideEffectMenubar.name}" must be used inside a "${SlideEffectMenuGroup.name}" component `,
    );
  }

  const isKeydown = useKeydownState();

  const { visibleItems, hiddenItems } = useResizeObserver(
    refList,
    groupOverflow,
    items,
  );

  const [navItems, groupedItems] = groupOverflow
    ? [visibleItems, hiddenItems]
    : [items, []];

  const computeGroupMenuWidth = () => {
    const width = Math.ceil(
      refGroupMenu.current?.getBoundingClientRect().width || 0,
    );
    return width + 'px';
  };

  const computeGroupMenuPosition = () => {
    const item = navItems.find(({ hidden }) => hidden === true);
    if (!item) return '0px';

    const node = refList!.current?.querySelector<HTMLElement>(`#${item.id}`);
    return (node?.offsetLeft || 0) + 'px';
  };

  useEffect(() => {
    setActiveNode(items);
  }, [items, navItems, groupedItems, setActiveNode]);

  return (
    <div
      className="flex gap-4"
      style={{
        minWidth: '200px',
        maxWidth,
      }}
    >
      <ul
        ref={refList}
        className={`${className || ''} m-0 flex gap-6 px-0 py-0
                    ${groupOverflow ? 'w-max' : ''}`}
        role="navigation"
        data-list
        {...dataProps}
        style={{
          maxWidth: groupOverflow
            ? `calc(${maxWidth} - ${computeGroupMenuWidth()})`
            : undefined,
        }}
      >
        {navItems.map((item) => (
          <li
            key={item.id}
            id={item.id}
            data-nav-item
            data-hidden={item.hidden}
            data-selected={item.selected}
            className={`flex-grow ${item.hidden ? 'opacity-0' : ''}`}
          >
            <BaseButton
              id={item.id}
              role="link"
              data-nav-content
              tabIndex={item.hidden ? -1 : 0}
              hidden={item.hidden}
              className={`link-navbar w-full leading-none
                        ${groupOverflow ? 'w-max' : ''}
                        ${!isKeydown ? 'no-active-focus' : ''}
                        ${item.selected ? 'text-shadow-bold' : ''}
            `}
              onClick={() => {
                onItemClick(item);
              }}
              onFocus={onItemFocus}
              onBlur={onItemBlur}
              onMouseOver={onItemFocus}
              onMouseLeave={onItemBlur}
            >
              {item.text}
            </BaseButton>
          </li>
        ))}
      </ul>

      {groupedItems.length > 0 ? (
        <div
          data-nav-group
          className="absolute"
          style={{
            left: computeGroupMenuPosition(),
          }}
        >
          <MenuList
            className={`z-300 ${groupedItems.length === 0 ? 'hidden' : ''}`}
            showOnMouseover={false}
            anchorComponent={({ ref, ...props }) => (
              <BaseButton
                ref={refGroupMenu}
                className={`w-max ${groupedItems.some((item) => item.selected) ? 'font-bold' : ''}`}
                {...props}
              >
                <span>{groupOverflowLabel}</span>
                <i className="fa-solid fa-chevron-down" />
              </BaseButton>
            )}
          >
            {groupedItems.map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                customTabIndex
                className={`p-block-1 ${item.selected ? 'text-shadow-bold' : ''}`}
                onClick={() => {
                  onItemClick(item);
                }}
              >
                <span
                  className="hover:text-maroon hover:underline"
                  tabIndex={0}
                >
                  {item.text}
                </span>
              </MenuItem>
            ))}
          </MenuList>
        </div>
      ) : null}
    </div>
  );
};

export { SlideEffectMenubar };
