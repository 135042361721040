import React, { FC } from 'react';

import { SpinnerLoader } from '..';

type Props = {
  className?: string;
  htmlTag?: keyof JSX.IntrinsicElements;
  loaderMessage?: React.ReactNode | string | null;
  customMessage?: React.ReactNode | string | null;
  hasShadow?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

const Box: FC<Props> = ({
  className,
  htmlTag = 'div',
  loaderMessage,
  customMessage,
  hasShadow = true,
  children,
}) => {
  const Container = htmlTag;
  const containsMessage = loaderMessage || customMessage;

  return (
    <Container
      className={`${className} relative ${hasShadow ? 'shadow-md' : ''}`}
    >
      {loaderMessage ? (
        <SpinnerLoader
          spinnerMessage={loaderMessage}
          spinnerType="circle"
          size="20px"
          attachContainer="element"
          fullOpacity
        />
      ) : customMessage ? (
        <div className="absolute inset-0 grid place-content-center">
          {customMessage}
        </div>
      ) : null}

      {containsMessage ? <div className="invisible">{children}</div> : children}
    </Container>
  );
};

export { Box };
