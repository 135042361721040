/**
 * Generic timeout to simulate a long delay
 * @param msTimeout Timeout in millisecond
 */
const pauseFor = async (msTimeout?: number) => {
  return await new Promise((resolve) => setTimeout(resolve, msTimeout));
};

function throttle<T extends (...args: any[]) => void>(
  func: T,
  limit: number,
): (...args: Parameters<T>) => void {
  let lastFunc: number;
  let lastRan: number;

  return (...args: Parameters<T>) => {
    // Use arrow function to capture `this` correctly
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = window.setTimeout(
        () => {
          if (Date.now() - lastRan >= limit) {
            func(...args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  };
}

function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export { debounce, pauseFor, throttle };
