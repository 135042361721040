import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authClient } from 'core/auth';
import { HTTP_FETCH_CACHE_KEY } from 'core/constants/app-constants';
import { apiBaseUrl } from 'core/constants/app-settings';
import { getIDPAuthToken } from 'core/services/idp/user-service';

type Primitive = string | number | boolean | undefined;
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export class CustomURL<TSearchParam extends Record<string, any>> extends URL {
  get searchParams() {
    return {
      ...super.searchParams,
      append: <K extends ExtractTypeKeyOf<TSearchParam, Primitive>>(
        name: K & string,
        value: TSearchParam[K],
      ) => {
        super.searchParams.append(name, value);
      },
    };
  }
}

const canHttpFetchCache = () => {
  const defaultValue = true;
  const value = localStorage.getItem(HTTP_FETCH_CACHE_KEY);
  return value ? value === 'true' : defaultValue;
};

const API = axios.create({
  baseURL: apiBaseUrl,
});
const CACHE_ENABLED = canHttpFetchCache();
const cachedData: Record<string, unknown> = {};

API.interceptors.request.use(async (config) => {
  const clientInstance = authClient?.getClientInstance();
  const serviceAuthToken = await clientInstance?.getAuthToken();
  const idpAuthToken = getIDPAuthToken();
  let jwt;

  if (config.headers['Use-ServiceAuth-Token']) {
    jwt = serviceAuthToken;
  } else {
    jwt = idpAuthToken;
  }

  config.headers['Authorization'] = `Bearer ${jwt?.token}`;

  return config;
});

export type HttpCacheStrategy = 'force-cache' | 'no-store';
export type HttpFetchParam<TDataResponse = unknown> =
  | string
  | {
      url: string;
      config?: Partial<AxiosRequestConfig>;
      cache?: HttpCacheStrategy;
      /** Use `cacheTag` to create unique cache key and avoid collisions.
       * Example: two `fetch` calls cache the response but one uses `cacheDataProcessor`
       * and the other does not, you might get unexpected behavior.
       */
      cacheTag?: string;
      /** It process the Response.data object prior to be cached */
      cacheDataProcessor?: (data: TDataResponse) => TDataResponse;
    };

const httpFetcher = async <TDataResponse>(
  instance: AxiosInstance,
  cachedData: Record<string, unknown>,
  param: HttpFetchParam<TDataResponse>,
) => {
  const {
    url,
    config = {},
    cacheTag = '',
    cache = 'no-store',
    cacheDataProcessor,
  } = typeof param === 'object'
    ? param
    : {
        url: param,
      };

  const cacheKey = cacheTag + url;

  if (CACHE_ENABLED && cache === 'force-cache' && cachedData[cacheKey]) {
    return cachedData[cacheKey] as AxiosResponse<TDataResponse>;
  }

  const { data, status } = await instance.get<TDataResponse>(url, config);
  cachedData[cacheKey] = {
    data: cacheDataProcessor?.(data) || data,
    status,
    config,
  };

  return cachedData[cacheKey] as AxiosResponse<TDataResponse>;
};

const fetchData = async <TDataResponse>(
  param: HttpFetchParam<TDataResponse>,
) => {
  return httpFetcher<TDataResponse>(API, cachedData, param);
};

const postData = async <TDataResponse, TPayloadRequest = unknown>(
  url: string,
  data: TPayloadRequest,
  config: AxiosRequestConfig = {},
) => {
  return API.post<TDataResponse, AxiosResponse<TDataResponse>, TPayloadRequest>(
    url,
    data,
    config,
  );
};

const putData = async <TDataResponse>(
  url: string,
  data: unknown,
  config: AxiosRequestConfig = {},
) => {
  return API.put<TDataResponse>(url, data, {
    ...config,
  });
};

const deleteData = async <TDataResponse>(
  url: string,
  config: AxiosRequestConfig = {},
) => {
  return API.delete<TDataResponse>(url, {
    ...config,
  });
};

// Once the web API will be ready, this will be replaced either by `fetch` or `axios` API
function fakeData<T>(
  mockData = {} as T,
  delay = 200,
): Promise<AxiosResponse<T, unknown>> {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve({
        data: mockData,
        status: 200,
        statusText: 'OK',
      } as AxiosResponse<T, unknown>);
    }, delay),
  );
}

export {
  fetchData,
  postData,
  putData,
  deleteData,
  httpFetcher,
  fakeData,
  apiBaseUrl,
};
