import React, { useCallback, useEffect, useState } from 'react';

import styles from './index.module.scss';

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'left' | 'center' | 'right';

type Props = {
  classes?: Partial<{
    backgroundColor: string;
    textColor: string;
  }>;
  /** If true, the component is shown. */
  open: boolean;
  /** The message to display. */
  message: React.ReactNode;
  /**
   * The number of milliseconds to wait before automatically calling the onClose function.
   * onClose should then set the state of the open prop to hide the Snackbar.
   * This behavior is disabled by default with the null value.
   */
  autoHideDuration?: number | null;
  /**
   * The anchor of the Snackbar.
   *
   * On smaller screens, the component grows to occupy all the available width,
   *  the horizontal alignment is ignored. */
  anchorOrigin?: {
    vertical: VerticalPosition;
    horizontal: HorizontalPosition;
  };
  /**
   * Callback fired when the component requests to be closed.
   * Typically onClose is used to set state in the parent component,
   *  which is used to control the Snackbar open prop.
   *  The reason parameter can optionally be used to control the response to onClose
   */
  onClose?: () => void;
};

const Snackbar: React.FC<Props> = ({
  open,
  message,
  autoHideDuration = null,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  classes = {},
  onClose,
}) => {
  const [visible, setVisible] = useState(open);

  const handleClose = useCallback(() => {
    setVisible(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (open || visible) {
      setVisible(true);

      if (autoHideDuration !== null) {
        const timer = setTimeout(() => {
          handleClose();
        }, autoHideDuration);

        return () => clearTimeout(timer);
      }
    }
  }, [open, visible, autoHideDuration, handleClose]);

  if (!visible) return null;

  const classNames: Props['classes'] = {
    backgroundColor: styles['bg-color'],
    textColor: styles['text-color'],
    ...classes,
  };

  // Determine dynamic positioning styles based on anchorOrigin
  const positionStyles: React.CSSProperties = {
    top: anchorOrigin.vertical === 'top' ? '20px' : 'auto',
    bottom: anchorOrigin.vertical === 'bottom' ? '20px' : 'auto',
    left:
      anchorOrigin.horizontal === 'left'
        ? '20px'
        : anchorOrigin.horizontal === 'center'
          ? '50%'
          : 'auto',
    right: anchorOrigin.horizontal === 'right' ? '20px' : 'auto',
    transform:
      anchorOrigin.horizontal === 'center' ? 'translateX(-50%)' : undefined,
  };

  return (
    <div
      role="presentation"
      className={`${styles.container} ${classNames.backgroundColor} ${classNames.textColor}`}
      style={positionStyles}
    >
      <span role="alert">{message}</span>

      <button className={styles['close-button']} onClick={handleClose}>
        <i className="fa-solid fa-xmark" />
      </button>
    </div>
  );
};

export { Snackbar };
