import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'core/utils';
import { NavMenuItem } from '..';

/** Hook to handle resize observer and return active node */
const useResizeObserver = <const T extends React.RefObject<HTMLElement>>(
  containerRef: T,
  enableResize: boolean,
  items: NavMenuItem[],
) => {
  const refSize = useRef<number>(0);
  const resizeObserver = useRef<ResizeObserver>();
  const [visibleItems, setVisibleItems] = useState<NavMenuItem[]>(items);

  const computeItemGroups = useCallback(() => {
    debounce(() => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();

      if (container.scrollWidth === containerRect.width) {
        return;
      }

      const hiddenStates = new Array(items.length).fill(false);
      const nodes = container.querySelectorAll<HTMLElement>('[data-nav-item]');

      for (let i = 0; i < nodes.length; i++) {
        const itemRect = nodes[i].getBoundingClientRect();

        const nodeEdge = itemRect.x + itemRect.width;
        const containerEdge = containerRect.x + containerRect.width;
        hiddenStates[i] = Math.round(nodeEdge) > Math.round(containerEdge);
      }

      const navItems = items.map((item, index) => ({
        ...item,
        hidden: hiddenStates[index],
      }));

      setVisibleItems(navItems);
    }, 200)();
  }, [items]);

  useEffect(() => {
    if (enableResize) {
      computeItemGroups();
    }
  }, [enableResize, computeItemGroups]);

  useEffect(() => {
    if (!enableResize) return;

    const list = containerRef.current;
    if (!list) return;

    resizeObserver.current = new ResizeObserver((entries) => {
      const entry = entries[0];
      const newWidth = Math.round(entry.contentRect.width);
      const oldWidth = Math.round(refSize.current);

      if (newWidth > 0 && newWidth !== oldWidth) {
        computeItemGroups();
        refSize.current = newWidth;
      }
    });

    resizeObserver.current.observe(list);

    return () => {
      resizeObserver.current?.disconnect();
    };
  }, [enableResize, computeItemGroups]);

  const navItems = visibleItems.map<NavMenuItem>((item, index) => ({
    ...item,
    selected: items[index].selected,
  }));

  return {
    visibleItems: navItems,
    hiddenItems: navItems.filter((item) => item.hidden),
  };
};

export { useResizeObserver };
