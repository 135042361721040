import { createContext, ElementRef, FC, useContext, useRef } from 'react';

import { isNumber } from 'core/utils/number-utils';
import { NavMenuItem } from '..';
import styles from './index.module.scss';

type Props = {
  className?: string;
  linkActiveColor?: string;
  children: React.ReactElement | React.ReactElement[];
};

function setTransform(target: HTMLElement | null, value: 'none' | '') {
  if (!target) return;
  target.style.transform = value;
  target.style.webkitTransform = value;
}

function setNavStyle(
  navbar: HTMLElement,
  target: HTMLElement | null,
  prop: 'hover' | 'active',
) {
  if (!target) return;

  setTransform(target, 'none');

  const left = target.offsetLeft;
  const width = target.offsetWidth;

  navbar.style.setProperty(`--link-${prop}-width`, `${width}px`);
  navbar.style.setProperty(`--link-${prop}-left`, `${left}px`);

  setTransform(target, '');
}

const SlideEffectMenuContext = createContext<{
  isParent?: boolean;
  onItemFocus: (event: { currentTarget: HTMLElement }) => Promise<void>;
  onItemBlur: () => Promise<void>;
  setActiveNode: (items: NavMenuItem[]) => void;
}>({
  onItemFocus: () => Promise.resolve(),
  onItemBlur: () => Promise.resolve(),
  setActiveNode: () => null,
});

const useSlideEffectMenuContext = () => useContext(SlideEffectMenuContext);

const SlideEffectMenuGroup: FC<Props> = ({
  className,
  linkActiveColor = 'var(--maroon)',
  children,
}) => {
  const navRef = useRef<ElementRef<'nav'>>(null);

  const onItemFocus = async ({
    currentTarget,
  }: {
    currentTarget: HTMLElement;
  }) => {
    await onItemBlur();
    setNavStyle(navRef.current!, currentTarget, 'hover');
    navRef.current!.setAttribute('data-link-hover', 'true');
  };

  const onItemBlur = async () => {
    navRef.current!.removeAttribute('data-link-hover');
  };

  const setActiveNode = (items: NavMenuItem[]) => {
    const nav = navRef.current!;

    const selectedItem = items.find((item) => item.selected);
    const isActive = !!selectedItem;

    nav.setAttribute('data-link-active', String(isActive));

    if (selectedItem) {
      const activateLink = async () => {
        await onItemBlur();
        const selector = isNumber(selectedItem.id)
          ? `[id="${selectedItem.id}"]`
          : `#${selectedItem.id}`;
        const navLink = nav.querySelector<HTMLElement>(selector);

        if (navLink?.dataset.hidden === 'true') {
          const navGroup =
            document.querySelector<HTMLElement>(`[data-nav-group]`);
          navGroup && setNavStyle(nav, navGroup, 'active');
        } else {
          setNavStyle(nav, navLink, 'active');
        }
      };
      activateLink();
    }
  };

  return (
    <SlideEffectMenuContext.Provider
      value={{
        isParent: true,
        onItemFocus,
        onItemBlur,
        setActiveNode,
      }}
    >
      <nav
        ref={navRef}
        className={`relative flex items-center py-2 ${styles.container} ${
          className || ''
        }`}
        style={{
          ['--link-active-color' as string]: linkActiveColor,
        }}
      >
        {children}
      </nav>
    </SlideEffectMenuContext.Provider>
  );
};

export { SlideEffectMenuGroup, useSlideEffectMenuContext };
