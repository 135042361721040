import { forwardRef } from 'react';

import { CustomInput } from '../types';
import styles from './index.module.scss';

type Props = CustomInput & {
  invalid?: boolean;
};

const NumberInput = forwardRef<HTMLInputElement, Props>(
  ({ invalid, maxLength, onChange, ...props }, ref) => (
    <div className={styles.container} data-invalid={invalid || undefined}>
      <input
        ref={ref}
        className="p-inline-4 p-block-2 max-w-fit border border-gray-500"
        type="number"
        data-invalid={invalid || undefined}
        onChange={(e) => {
          if (maxLength && e.target.value.length > maxLength) return;
          onChange?.(e);
        }}
        {...props}
      />
    </div>
  ),
);

export { NumberInput };
