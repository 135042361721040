import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import { AuthProvider } from 'core/auth';
import { SpinnerLoader } from 'core/components';
import { authSettings } from 'core/constants/auth-settings';
import { AppProvider } from 'core/context/app-context';
import {
  getUserRoles,
  requestIDPAuthToken,
} from 'core/services/idp/user-service';
import { stopViewAsUser } from 'core/services/ui/user-view-as-service';
import { store } from 'core/store';
import { formatLog } from 'core/utils/logger-utils';
import 'dev/dev-tools';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

document.body.insertAdjacentHTML(
  'afterbegin',
  `<div id="body-top-section" ></div>`,
);
// =======================================================================
// DEV mode - Component mounted twice
const StrictMode = React.StrictMode;
// =======================================================================
// PROD mode - Component mounted once
// =======================================================================
// Use this wrapper to test it as in prod mode. Why? Use of `localStorage`
// might give misleading results after the second component mount
// const StrictMode = React.Fragment;
// =======================================================================

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider
        authProps={{
          customSettings: authSettings,
          Loader: ({ message }) => (
            <SpinnerLoader
              spinnerMessage={<span className="text-2xl">{message}</span>}
              fullSize
            />
          ),
          getUserRoles: async () => {
            const data = await getUserRoles();
            console.log(
              ...formatLog({
                action: 'GET-ROLES',
                message: `Get IDP roles`,
              }),
            );
            return data.roles;
          },
        }}
        onTokenUpdate={async ({ action }) => {
          await requestIDPAuthToken();
          console.log(
            ...formatLog({
              action,
              message: `Requested IDP Auth Token`,
            }),
          );
        }}
        onRedirect={stopViewAsUser}
      >
        <Provider store={store}>
          <AppProvider>
            <App />
          </AppProvider>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
