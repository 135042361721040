import React, { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  /**
   * Use this prop to override the component base styles
   */
  baseClassName?: string;
  /**
   * Use this prop to customize the component style
   */
  className?: string;
  hidden?: boolean;
} & React.ComponentProps<'button'>;

const BaseButton: FC<Props> = React.forwardRef(
  (
    {
      children,
      className = '',
      baseClassName = 'inline-flex items-center justify-center gap-2 hover:scale-105',
      disabled,
      hidden,
      onClick,
      onFocus,
      onBlur,
      onMouseOver,
      onMouseLeave,
      ...props
    },
    ref,
  ) => {
    const eventDisabled = disabled || hidden;

    return (
      <button
        ref={ref}
        type="button"
        className={`${styles.container} ${baseClassName} ${className}`}
        disabled={disabled}
        data-hidden={hidden}
        onClick={eventDisabled ? undefined : onClick}
        onFocus={eventDisabled ? undefined : onFocus}
        onBlur={eventDisabled ? undefined : onBlur}
        onMouseOver={eventDisabled ? undefined : onMouseOver}
        onMouseLeave={eventDisabled ? undefined : onMouseLeave}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export { BaseButton };
