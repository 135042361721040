import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpCode } from 'core/constants/app-constants';
import {
  getActivePlan,
  updateActivePlan,
} from 'core/services/idp/plan-service';
import { preProcessPlanForUI } from 'core/services/ui/plan-ui-data-service';
import { errorUtils } from 'core/utils/error-utils';
import { dispatchThunkError, pickEmplid } from './utils';

const initialState: PlanActiveState = {
  isDefaultState: true,
  status: 'pending',
  noActivePlanFound: false,
  data: null,
};

export const planActiveSlice = createSlice({
  name: 'planActive',
  initialState,
  reducers: {
    setPlanStatus: (state, action: ActionOf<PlanStatus>) => {
      state.status = action.payload;
    },
    setPlanActiveNotFound: (state, action: ActionOf<boolean>) => {
      state.noActivePlanFound = action.payload;
      state.isDefaultState = false;
    },
    setActivePlan: (
      state,
      { payload: rawPlan }: ActionOf<API.PlanData.Plan>,
    ) => {
      const planData = preProcessPlanForUI({ rawPlan })!;
      state.data = planData;
      state.isDefaultState = false;
      state.noActivePlanFound = false;
    },
    setActivePlanToDefault: (state) => {
      state.status = 'ready';
      state.isDefaultState = true;
      state.data = initialState.data;
    },
  },
});

export const getActivePlanAsync = createAsyncThunk(
  'planSlice/getActivePlanAsync',
  async (
    {
      searchEmplId,
    }:
      | undefined
      | {
          searchEmplId?: string;
        } = {},
    { dispatch, getState, signal },
  ) => {
    dispatch(setPlanStatus('loading'));
    dispatch(setActivePlanToDefault());
    try {
      const emplid = searchEmplId || pickEmplid(getState());
      const plan = await getActivePlan(emplid, signal);
      dispatch(setActivePlan(plan));
      dispatch(setPlanStatus('ready'));
    } catch (error) {
      const err = errorUtils(error);

      if (err.isFetchAbort) return;

      if (err.status === HttpCode.NOT_FOUND) {
        dispatch(setPlanActiveNotFound(true));
      } else {
        dispatchThunkError(dispatch, error, getActivePlanAsync);
      }
      dispatch(setPlanStatus('failed'));
    }
  },
);

export const updateActivePlanAsync = createAsyncThunk(
  'planSlice/updateActivePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    dispatch(setPlanStatus('saving'));
    try {
      const emplid = pickEmplid(getState());
      const plan = await updateActivePlan(emplid, planId);

      dispatch(setActivePlan(plan));
      dispatch(setPlanActiveNotFound(false));
      dispatch(setPlanStatus('ready'));
      return { saved: true };
    } catch (error) {
      dispatchThunkError(dispatch, error, updateActivePlanAsync);
      return { error, saved: false };
    }
  },
);

export const {
  setPlanActiveNotFound,
  setPlanStatus,
  setActivePlan,
  setActivePlanToDefault,
} = planActiveSlice.actions;

export default planActiveSlice.reducer;
