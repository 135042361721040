import { useEffect, useState } from 'react';

/** Hook to detect keyboard activity */
const useKeydownState = () => {
  const [isKeydown, setKeydown] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    document.addEventListener('keydown', () => setKeydown(true), { signal });
    document.addEventListener('mousedown', () => setKeydown(false), { signal });

    return () => controller.abort();
  }, []);

  return isKeydown;
};

export { useKeydownState };
