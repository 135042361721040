import { HTTP_FETCH_CACHE_KEY } from 'core/constants/app-constants';

type DevTools = Partial<{
  enableDebugMode: (enable: boolean) => void;
  isDebugMode?: () => boolean;
  enableHttpFetchCache: (enable: boolean) => void;
}>;
declare global {
  interface Window {
    ___ASU_DEV_TOOLS___: DevTools;
    navigation?: {
      addEventListener: (
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions,
      ) => void;
    };
  }
}

const host = window.location.host;
const REG_EX = /(local.)|(-dev.)|(-qa.)|(sandbox.)/;
const DEPLOY_ENV = host.match(REG_EX) ? 'NON-PROD' : null;

const ENABLE_DEV_TOOLS =
  DEPLOY_ENV === 'NON-PROD' || process.env.NODE_ENV === 'development';

const DEBUG_KEY = 'asu-dev-tools.dev-mode';

const storage = localStorage;

const attachTooltip = () => {
  document
    .querySelectorAll<HTMLElement>(`[data-dev-title]`)
    ?.forEach((element) => {
      const title = element.getAttribute('data-dev-title') || '';
      element.setAttribute('title', title);
    });
};

const attachHover = () => {
  document.head.insertAdjacentHTML(
    'beforeend',
    `<style>
        [data-dev-hover]:hover {
          outline: solid 2px var(--maroon);
          border-radius: 5px;
        }
      </style>`,
  );
};

const attachDevInfo = () => {
  setTimeout(() => {
    attachTooltip();
    attachHover();
  }, 4000);
};

const isDevMode = () => {
  return storage.getItem(DEBUG_KEY) === 'true';
};

const devTools = ENABLE_DEV_TOOLS
  ? {
      isDevMode,
      enableDevMode: (enable: boolean = true) => {
        storage.setItem(DEBUG_KEY, String(enable));
        console.log('<< Dev mode enabled >>');

        attachDevInfo();

        if (window.navigation) {
          window.navigation.addEventListener('navigate', () => {
            setTimeout(attachTooltip, 4000);
          });
        }
      },
      enableHttpFetchCache: (enable: boolean) => {
        storage.setItem(HTTP_FETCH_CACHE_KEY, String(enable));
      },
    }
  : {
      isDevMode,
    };

window.___ASU_DEV_TOOLS___ = devTools;

if (devTools.isDevMode()) {
  attachDevInfo();
}

export { devTools };
