import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Navbar } from 'core/components';
import { AlertProvider, LoaderProvider } from 'core/context';
import { useAppSelector } from 'core/store';

const PageLayout: FC = () => {
  const showFooter = useAppSelector((state) => state.shared.showFooter);

  return (
    <>
      <Navbar />
      <AlertProvider>
        <main className="app">
          <LoaderProvider>
            <Outlet />
          </LoaderProvider>
        </main>
      </AlertProvider>
      {!showFooter ? null : <Footer />}
    </>
  );
};

export { PageLayout };
