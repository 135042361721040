import { parsePlanId } from 'core/utils/data-utils';
import { apiBaseUrl, deleteData, postData } from 'core/utils/http-utils';
import { HttpQueueWorkerManager } from 'core/workers/http-queue-worker';
import { NO_SESSION_NAME } from '../ui/plan-ui-data-service/process-term-sessions';
import { getIDPAuthToken } from './user-service';

function buildUrl(
  emplid: string,
  {
    planId,
    termCode,
    sessionCode,
    courseId,
    isRequirement,
  }: API.PlanData.ClassMoveUrlParams,
) {
  const subPath = isRequirement ? 'requirements' : 'courses';
  const url =
    `/api/${emplid}/plans/${parsePlanId(planId)}/terms/${termCode}` +
    `/sessions/${sessionCode || NO_SESSION_NAME}/${subPath}/${courseId}`;

  return url;
}

async function updatePlanCourses(
  emplid: string,
  planId: string,
  dragItem: ClassDragItemData,
  planWorker: HttpQueueWorkerManager,
) {
  const { target, source } = dragItem;

  const isRequirement = source.isRequirement,
    courseType = source.status,
    creditHours = source.creditHours;

  const url = buildUrl(emplid, {
    planId,
    isRequirement,
    termCode: source.termCode,
    sessionCode: source.sessionCode,
    courseId: source.cardId,
  });

  const payload = {
    courseType: isRequirement ? undefined : courseType,
    creditHours: isRequirement ? creditHours : undefined,
    // target
    newTermCode: target.termCode,
    newTermName: target.termName,
    newSessionCode: target.sessionCode,
  };

  const jwt = getIDPAuthToken();
  // Change after: https://asudev.jira.com/browse/CMIDP-1715
  // The user can only move course when the plan change is completed
  // The queue is going to have only one request at a time.
  planWorker.enqueueRequest({
    method: 'PUT',
    url: `${apiBaseUrl}${url}`,
    authorization: `Bearer ${jwt?.token}`,
    data: payload,
    tags: {
      dragItem,
    },
  });
}

async function createPlanCourse(
  emplid: string,
  planId: string,
  payload: API.PlanData.ClassCreatePayload,
) {
  const url = `api/${emplid}/plans/${planId}/course`;
  const response = await postData<API.HttpPlanAnyResponse>(url, payload);

  return response;
}

async function deletePlanCourse(
  emplid: string,
  planId: string,
  payload: API.PlanData.ClassDeleteUrlParams,
) {
  const {
    termCode,
    sessionCode,
    subject,
    catalogNumber,
    courseType,
    classNumber,
  } = payload;
  const BASE_URL = `api/${emplid}/plans/${planId}`;

  let courseId = '';
  let url = '';

  // In progress Class
  if (courseType === 'not-started' || courseType === 'in-progress') {
    url = `${BASE_URL}/terms/${termCode}/sessions/${sessionCode}/classes/${classNumber}`;
  }
  // Planned course
  else {
    courseId = `${termCode}:${sessionCode}:${subject}:${catalogNumber}`;
    url = `${BASE_URL}/course/${courseId}`;
  }

  const response = await deleteData<API.HttpPlanAnyResponse>(url);

  return response;
}

export { createPlanCourse, deletePlanCourse, updatePlanCourses };
