import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';

const selectPlanList = createSelector(
  [(state: RootState) => state.plan, (state: RootState) => state.planActive],

  (plan, planActive) => {
    const activePlan = planActive.data;
    const activePlanLoading = planActive.status === 'loading';
    const { planListLoaded, planListLoading, dataList } = plan;

    const planList = dataList;
    const whatIfPlans = planList.filter((plan) => plan.id !== activePlan?.uuid);

    const hasActivePlan = !!activePlan;
    const hasPlans = dataList?.length > 0 || hasActivePlan;
    const planPending = activePlanLoading || planListLoading || !planListLoaded;

    return {
      activePlan,
      whatIfPlans,
      planList,
      hasPlans,
      planPending,
    };
  },
);

const usePlanListSelector = () => useAppSelector(selectPlanList);

export { selectPlanList, usePlanListSelector };
