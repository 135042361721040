import { AxiosError } from 'axios';

const errorUtils = (error: unknown) => {
  let status: number | undefined;
  let isFetchAbort: Boolean | undefined = false;

  if (error instanceof AxiosError) {
    isFetchAbort = error.config?.signal?.aborted;
    status = error.response?.status;
  }

  return {
    error,
    isFetchAbort,
    status,
  };
};

export { errorUtils };
