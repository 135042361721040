import { ElementRef, FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  size?: `${number}${string}` | `inherit`;
  offsetTop?: number;
  offsetLeft?: number;
  centerLeft?: boolean;
  centerTop?: boolean;
  [x: `data-${string}`]: any;
} & (
  | {
      iconClassName: string;
      renderIcon?: never;
    }
  | {
      iconClassName?: never;
      renderIcon: React.ReactNode;
    }
);

const Tooltip: FC<Props> = ({
  children,
  iconClassName,
  renderIcon,
  disabled = false,
  size = 'inherit',
  offsetTop = 0,
  offsetLeft = 0,
  centerTop = false,
  centerLeft = false,
  ...props
}) => {
  const tooltipRef = useRef<ElementRef<'span'>>(null);

  const hideTooltip = () => {
    const tooltip = tooltipRef.current!;
    tooltip.classList.add('hidden');
    tooltip.style.setProperty('opacity', '0');
  };

  return (
    <span
      className={`${
        styles.container
      } relative flex items-center justify-center ${
        disabled ? 'text-gray-500' : 'text-gray-700'
      }`}
      style={{
        ['--size' as string]: size,
      }}
      data-disabled={disabled}
      onMouseLeave={hideTooltip}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <span
        className="flex"
        onMouseOver={(e) => {
          const { height, y, width, x } =
            e.currentTarget.getBoundingClientRect();

          const tooltip = tooltipRef.current!;
          const top = y + height + offsetTop + 'px';
          const left = x + width + offsetLeft + 'px';

          tooltip.classList.remove('hidden');
          tooltip.style.setProperty('top', top);
          tooltip.style.setProperty('left', left);
          tooltip.style.setProperty('opacity', '1');

          if (centerTop && centerLeft) {
            tooltip.style.setProperty('transform', 'translate(-50%, -50%)');
          } else if (centerTop) {
            tooltip.style.setProperty('transform', 'translateY(-50%)');
          } else if (centerLeft) {
            tooltip.style.setProperty('transform', 'translateX(-50%)');
          }
        }}
      >
        {iconClassName ? <i className={iconClassName} /> : renderIcon}
      </span>

      {createPortal(
        <span
          ref={tooltipRef}
          className="shadow-base fade-in z-1000 max-w-50ch absolute hidden
                    text-pretty bg-white p-4 tracking-tighter"
          style={{ top: offsetTop + 'px', left: offsetLeft + 'px' }}
          onMouseLeave={hideTooltip}
        >
          {children}
        </span>,
        document.body,
        'tooltip',
      )}
    </span>
  );
};

export { Tooltip };
